.eventContainer {
    cursor: pointer;
    gap: 8px;
    background-color: #FFF;
    border: 2px solid #002147;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    &:hover {
        background-color: #F5F5F5;
    }

    &.selectedEvent {
        &.viewingEvent {
            background-color: #dddddd;
        }
        
        @media only screen and (min-width: 768px) {
            background-color: #dddddd;
        }
    }

    .attendingIcon {
        position: absolute;
        background-color: #002147;
        border-radius: 50%;
        top: 8px;
        right: 8px;

        > svg {
            fill: #FFC200;
        }
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 20vh;

        @media only screen and (min-width: 768px) {
            height: 15vh;
        }

        .uploadedImageContainer {
            width: 100%;
            height: 100%;

            .imageBlurContainer {
                display: flex;
                justify-content: center;
                height: 100%;
                backdrop-filter: blur(20px);
            }
        }

        .image {
            height: 100%;

            &.logo {
                background-color: #F5F5F5;
                border-bottom: #FFC200 1px solid;
                width: 100%;

                svg {
                    height: 100%;
                    width: 100%;
                    fill: #FFC200;
                }
            }
        }
    }

    .eventInfo {
        padding: 0 16px 16px;

        .title {
            margin-top: 4px;
            font-weight: 600;
            font-size: large;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        .location {
            margin: 8px 0;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        .time {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }
}