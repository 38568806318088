.eventDisplayContainer {
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffda66;
    
    .eventPreviewContainer {
        height: 100%;
        border-radius: 4px;
        background-color: #FFF;

        .emptyContainer {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .eventPreview {
            overflow-y: auto;
            position: relative;

            // TODO: Make more stylish by getting header (back to events) & footer heights and join event button
            height: calc(100% - 115px);

            &.loggedOut {
                height: calc(100% - 45px);
            }

            @media only screen and (min-width: 768px) {
                height: calc(100% - 75px);

                &.loggedOut {
                    height: 100%;
                }
            }

            &.modalOpen {
                height: 100%;
                padding: 0;
            }
            
            p {
                margin: 0;
            }

            .actionsContainer {
                position: absolute;
                top: 15%;
                flex-direction: column;
                right: 10px;
                gap: 8px;
                display: flex;

                @media only screen and (min-width: 768px) {
                    top: 10px;
                    flex-direction: row;
                }

                .actionButton {
                    border-radius: 50%;
                    cursor: pointer;
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-filter: brightness(100%);

                    &:hover {
                        -webkit-filter: brightness(70%);
                    }

                    > svg {
                        width: 28px;
                    }

                    &.edit {
                        background-color: #556B2F;
                        
                        > svg {
                            fill: #FFC200;
                        }
                    }
                    
                    &.delete {
                        background-color: #FF6347;
                        
                        > svg {
                            fill: #FFF;
                        }
                    }
                }
            }

            .eventDisplayInfoContainer {
                border-radius: 4px;
                overflow: hidden;

                &.isClosed {
                    display: none;

                    @media only screen and (min-width: 768px) {
                        display: block;
                    }
                }

                .eventInformationContainer {
                    padding: 0 16px 16px 16px;

                    .infoContainer {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 20px;

                        .title {
                            font-size: 32px;
                            line-height: 44px;
                        }

                        .sizedInfo {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        .infoItem {
                            margin-bottom: 16px;
                        }

                        .infoItemText {
                            text-align: center;
                            font-size: 20px;
                            line-height: 26px;
                        }

                        .eventName {
                            text-align: center;
                            font-size: 32px;
                            line-height: 44px;
                        }

                        .eventLocation {
                            text-align: center;
                            font-size: 24px;
                            line-height: 34px;
                        }

                        .attendeeList {
                            display: flex;
                            align-items: center;
                        }

                        .attendeeImagesContainer {
                            display: flex;
                            flex-direction: row;
                            width: 140px;
                            justify-content: space-around;
                            margin-bottom: 4px;
                        }

                        .attendeeImage {
                            height: 40px;
                            width: 40px;
                            border-radius: 25px;
                            background-color: #FFC200;
                        }

                        .attendeeInfoRow {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 12px;
                            font-size: 24px;
                            line-height: 34px;
                        }

                        .attendeeInfoRowImage {
                            height: 60px;
                            width: 60px;
                            border-radius: 30px;
                        }

                        .attendeeSeparator {
                            margin: 16px 0;
                        }

                        .locationText {
                            text-align: center;
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }

                    .eventDescription {
                        white-space: pre-line;
                    }
                }
            }
        }
    }

    .rsvpContainer {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding: 0 20px;

        &.closedRsvpContainer {
            display: none;

            @media only screen and (min-width: 768px) {
                display: flex;
            }
        }

        > div, > div > button {
            width: 100%;
        }
    }
}